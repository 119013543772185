var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Box, Row, Text } from 'elemental-react';
import { NameText, UsernameText } from '../BasePost';
import { getTimeAgo } from '../../utils/time';
import ProfileIcon from '../../profile/ProfileIcon';
import QuotePost from '../QuotePost';
import { FavoriteIcon, ReplyIcon, RepostIcon, ShareIcon, ZcashIcon } from '../../icons';
var MicroPostFeedItem = function (_a) {
    var id = _a.id, user = _a.user, retweetUser = _a.retweetUser, createdAt = _a.createdAt, isRepliedTo = _a.isRepliedTo, retweetedStatus = _a.retweetedStatus, quotedStatus = _a.quotedStatus, text = _a.text, inReplyToStatusId = _a.inReplyToStatusId;
    var _b = useState('test'), memoContent = _b[0], setMemoContent = _b[1];
    var _c = useState(false), tipVisible = _c[0], setTipVisible = _c[1];
    return (_jsxs(Box, { children: [retweetedStatus && (_jsxs(Row, __assign({ px: [16, 40], alignItems: "center", pt: 12 }, { children: [_jsxs(Row, __assign({ width: 40, mr: 12 }, { children: [_jsx(Box, { flex: 1 }, void 0),
                            _jsx(RepostIcon, { fill: "#6D6D6D" }, void 0)] }), void 0),
                    _jsx(Text, __assign({ fontFamily: "Helvetica", fontSize: 14, color: "#6D6D6D" }, { children: retweetUser.name + " Retweeted" }), void 0)] }), void 0)),
            _jsxs(Row, __assign({ py: 12, px: [16, 40] }, { children: [_jsxs(Box, __assign({ mr: 12, alignItems: "center" }, { children: [inReplyToStatusId && _jsx(Box, { width: "2px", height: 32, bg: "#B5B5B5", mb: 2 }, void 0),
                            _jsx(ProfileIcon, { size: 40, uri: user.profile_image_url_https }, void 0),
                            isRepliedTo && _jsx(Box, { width: "2px", flex: 1, bg: "#B5B5B5", mb: 2 }, void 0)] }), void 0),
                    _jsxs(Box, __assign({ flex: 1 }, { children: [_jsxs(Row, __assign({ justifyContent: "space-between", flex: 1 }, { children: [_jsxs(Box, { children: [_jsx(NameText, __assign({ mb: 1 }, { children: user.name }), void 0),
                                            _jsx(UsernameText, { children: "@" + user.screen_name }, void 0)] }, void 0),
                                    createdAt && _jsx(Text, __assign({ fontFamily: "Helvetica", fontSize: 16 }, { children: getTimeAgo(createdAt) }), void 0)] }), void 0),
                            _jsx(Box, __assign({ pt: "4px" }, { children: text ? (_jsx(Text, __assign({ fontSize: 16, fontFamily: "Helvetica" }, { children: text }), void 0)) : (_jsxs(_Fragment, { children: [_jsx(Box, { bg: "#DEDEDE", height: 16, width: "90%", mb: "4px" }, void 0),
                                        _jsx(Box, { bg: "#DEDEDE", height: 16, width: "80%", mb: "4px" }, void 0),
                                        _jsx(Box, { bg: "#DEDEDE", height: 16, width: "90%", mb: "4px" }, void 0)] }, void 0)) }), void 0),
                            quotedStatus && (_jsx(QuotePost, { user: quotedStatus.user, createdAt: new Date(quotedStatus.created_at), text: quotedStatus.text }, void 0)),
                            _jsxs(Row, __assign({ mt: 12, justifyContent: "space-between" }, { children: [[
                                        { component: ReplyIcon, id: 'reply' },
                                        { component: RepostIcon, id: 'repost' },
                                        { component: FavoriteIcon, id: 'favorite' },
                                        { component: ShareIcon, id: 'share' },
                                    ].map(function (_a) {
                                        var Comp = _a.component, actionId = _a.id;
                                        var hrefById = {
                                            reply: "https://twitter.com/intent/tweet?in_reply_to=" + id,
                                            repost: "https://twitter.com/intent/retweet?tweet_id=" + id,
                                            favorite: "https://twitter.com/intent/like?tweet_id=" + id,
                                        };
                                        return ( // @ts-ignore
                                        _jsx(Box, __assign({ mr: 16, as: "a", href: actionId === 'share' ? '#' : hrefById[actionId], target: actionId !== 'share' && '_blank', onClick: (actionId === 'share') ? function () { if (typeof navigator !== 'undefined' && (navigator === null || navigator === void 0 ? void 0 : navigator.canShare) && navigator.share) {
                                                navigator === null || navigator === void 0 ? void 0 : navigator.share({ url: "https://twitter.com/" + user.screen_name + "/" + id });
                                            } } : undefined }, { children: _jsx(Comp, { fill: "#5F6E7A" }, void 0) }), void 0));
                                    }),
                                    _jsx(Box, { flex: 1 }, void 0),
                                    _jsx(Box, __assign({ as: "a" // @ts-ignore
                                        , href: "#", onClick: function () {
                                            setMemoContent("TIP_TWEET:" + id);
                                            setTipVisible(!tipVisible);
                                        } }, { children: _jsx(ZcashIcon, {}, void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0)] }, void 0));
};
export default MicroPostFeedItem;
