var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-ignore
import { Svg, Path } from '@react-platform/svg';
var ReplyIcon = function (_a) {
    var _b = _a.fill, fill = _b === void 0 ? '#000' : _b;
    return ( // @ts-ignore
    _jsxs(Svg, __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", overflow: "visible" }, { children: [_jsx(Path, { fill: "none", d: "M0 0h24v24H0z" }, void 0),
            _jsx(Path, { fill: fill, d: "M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z" }, void 0)] }), void 0));
};
export default ReplyIcon;
