var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from 'elemental-react';
import { G, Rect, Svg } from 'react-primitives-svg';
import AppBar from '../common/AppBar';
import Footer from '../common/Footer';
var CloseIcon = function () { return (_jsx(Svg, __assign({ width: "48px", height: "48px", viewBox: "0 0 48 48" }, { children: _jsx(G, __assign({ stroke: "none", "stroke-width": "1", fill: "none", "fill-rule": "evenodd" }, { children: _jsx(G, __assign({ transform: "translate(-16.000000, -16.000000)" }, { children: _jsxs(G, __assign({ transform: "translate(16.000000, 16.000000)" }, { children: [_jsx(Rect, { x: "0", y: "0", width: "48", height: "48" }, void 0),
                    _jsx(G, __assign({ transform: "translate(24.000000, 24.000000) rotate(-315.000000) translate(-24.000000, -24.000000) translate(4.000000, 22.000000)", fill: "#000000" }, { children: _jsx(Rect, { x: "0", y: "0", width: "40", height: "4" }, void 0) }), void 0),
                    _jsx(G, __assign({ transform: "translate(8.443651, 8.443651)", fill: "#000000" }, { children: _jsx(Rect, { transform: "translate(15.556349, 15.556349) rotate(-45.000000) translate(-15.556349, -15.556349) ", x: "-4.44365081", y: "13.5563492", width: "40", height: "4" }, void 0) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0)); };
var NavOverlay = function (_a) {
    var title = _a.title, onMenuClick = _a.onMenuClick;
    return (_jsxs(Box, __assign({ flex: 1 }, { children: [_jsxs(AppBar, { children: [_jsx(Box, __assign({ onClick: onMenuClick }, { children: _jsx(CloseIcon, {}, void 0) }), void 0),
                    title ? _jsx(AppBar.Title, { children: title }, void 0) : _jsx(AppBar.Title, {}, void 0),
                    _jsx(AppBar.Fill, {}, void 0)] }, void 0),
            _jsx(Box, __assign({ flex: 1, justifyContent: "center", alignItems: "center" }, { children: ['Home', 'Microblog', 'Videos', 'Photos', 'Articles', 'About'].map(function (name) { return (_jsx(Box, __assign({ p: 20 }, { children: _jsx(Text, __assign({ color: "black", fontSize: 32, fontFamily: "Roboto", bold: true }, { children: name === null || name === void 0 ? void 0 : name.toUpperCase() }), void 0) }), void 0)); }) }), void 0),
            _jsx(Footer, {}, void 0)] }), void 0));
};
export default NavOverlay;
