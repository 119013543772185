import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
export var getTimeAgo = function (createdAt) {
    if (!createdAt) {
        return '';
    }
    var timeAgoText;
    if (differenceInDays(new Date(), createdAt) <= 0) {
        if (differenceInHours(new Date(), createdAt) === 0) {
            timeAgoText = differenceInMinutes(new Date(), createdAt) + "m";
        }
        else {
            timeAgoText = differenceInHours(new Date(), createdAt) + "h";
        }
    }
    else {
        var formatter = new Intl.DateTimeFormat('en-US', { month: 'short' });
        var shortMonth = formatter.format(createdAt);
        timeAgoText = shortMonth + " " + createdAt.getDate();
    }
    return timeAgoText;
};
