var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { extend, Text, Box } from 'elemental-react';
export var NameText = extend(Text, function () { return ({
    fontFamily: 'Helvetica',
    fontSize: 16,
    lineHeight: 20,
    bold: true,
}); });
export var UsernameText = extend(Text, function () { return ({
    fontFamily: 'Helvetica',
    fontSize: 12,
    color: '#737373'
}); });
export var TextPlaceHolder = function () { return (_jsxs(_Fragment, { children: [_jsx(Box, { bg: "#DEDEDE", height: 16, width: "90%", mb: "4px" }, void 0),
        _jsx(Box, { bg: "#DEDEDE", height: 16, width: "80%", mb: "4px" }, void 0),
        _jsx(Box, { bg: "#DEDEDE", height: 16, width: "90%", mb: "4px" }, void 0)] }, void 0)); };
export var ProfileNamesRow = function (_a) {
    var username = _a.username, name = _a.name;
    return (_jsxs(Box, { children: [_jsx(NameText, __assign({ mb: 1 }, { children: name }), void 0),
            _jsx(UsernameText, { children: "@" + username }, void 0)] }, void 0));
};
export var AnonProfileNamesRow = function (_a) {
    var username = _a.username, name = _a.name;
    return (_jsxs(Box, { children: [_jsx(NameText, __assign({ color: "#737373", fontWeight: 500, mb: 1 }, { children: name }), void 0),
            _jsx(UsernameText, __assign({ fontFamily: "primary" }, { children: "" + username }), void 0)] }, void 0));
};
export var PostText = function (_a) {
    var text = _a.text;
    return (_jsx(Box, __assign({ pt: "4px" }, { children: text ? (_jsx(Text, __assign({ fontSize: 16, fontFamily: "Helvetica" }, { children: text }), void 0)) : _jsx(TextPlaceHolder, {}, void 0) }), void 0));
};
