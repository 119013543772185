var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Image } from 'elemental-react';
var ProfileIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 40 : _b, uri = _a.uri, children = _a.children, props = __rest(_a, ["size", "uri", "children"]);
    return (_jsx(_Fragment, { children: uri
            ? _jsx(Image, __assign({ width: size, height: size, borderRadius: "50%", src: uri }, props), void 0)
            : (children
                ? (_jsx(Box, __assign({ borderRadius: "50%", bg: "white", justifyContent: "center", alignItems: "center" // @ts-ignore
                    , p: "4px", size: size }, props, { children: children }), void 0)) : _jsx(Box, __assign({ borderRadius: "50%", width: size, height: size, bg: "#DEDEDE", size: size }, props), void 0)) }, void 0));
};
export default ProfileIcon;
