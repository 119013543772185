var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, Row } from 'elemental-react';
import { Svg, Circle } from 'react-primitives-svg';
var Footer = function () { return (_jsxs(Box, __assign({ alignItems: "center", p: 32 }, { children: [_jsx(Row, __assign({ alignItems: "center", mb: 3 }, { children: ['Terms of Service', 'Privacy Policy', 'Content Policy'].map(function (name, i, __) { return (_jsxs(_Fragment, { children: [_jsx(Text, __assign({ fontFamily: "Roboto", fontSize: 14 }, { children: name }), void 0),
                    i >= 0 && i < (__.length - 1) && (_jsx(Box, __assign({ px: 2 }, { children: _jsx(Svg, __assign({ viewBox: "0 0 100 100", height: 4, width: 4 }, { children: _jsx(Circle, { cx: "50", cy: "50", r: "50" }, void 0) }), void 0) }), void 0))] }, void 0)); }) }), void 0),
        _jsx(Row, { children: _jsxs(Text, __assign({ fontFamily: "Roboto", fontSize: 16 }, { children: ["\u00A9 ", new Date().getFullYear(), " ZPublish"] }), void 0) }, void 0)] }), void 0)); };
export default Footer;
