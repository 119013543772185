var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, Row } from 'elemental-react'; // @ts-ignore
import { Icon } from '@elemental-zcash/components';
import { ShieldIcon } from '@elemental-zcash/icons';
import ProfileIcon from '../../profile/ProfileIcon';
import { AnonProfileNamesRow, PostText } from '../BasePost';
import { getTimeAgo } from '../../utils/time';
var ZecPostFeedItem = function (_a) {
    var username = _a.username, name = _a.name, createdAt = _a.createdAt, inReplyToStatusId = _a.inReplyToStatusId, isRepliedTo = _a.isRepliedTo, text = _a.text, props = __rest(_a, ["username", "name", "createdAt", "inReplyToStatusId", "isRepliedTo", "text"]);
    return (_jsx(Box, __assign({ bg: "white", borderRadius: 12 }, props, { children: _jsxs(Box, __assign({ py: 12, px: [16, 40] }, { children: [_jsxs(Row, __assign({ justifyContent: "space-between", flex: 1 }, { children: [_jsxs(Box, __assign({ mr: 12, alignItems: "center" }, { children: [inReplyToStatusId && _jsx(Box, { width: "2px", height: 32, bg: "#B5B5B5", mb: 2 }, void 0),
                                _jsx(ProfileIcon, __assign({ size: 40, bg: "white", borderColor: "#D9D9D9", borderWidth: 1 }, { children: _jsx(Icon, { icon: ShieldIcon, color: "primary" }, void 0) }), void 0),
                                isRepliedTo && _jsx(Box, { width: "2px", flex: 1, bg: "#B5B5B5", mb: 2 }, void 0)] }), void 0),
                        _jsx(AnonProfileNamesRow, { username: username || 'zs*****', name: name || 'ANONYMOUS' }, void 0),
                        _jsx(Box, { flex: 1 }, void 0),
                        createdAt && _jsx(Text, __assign({ fontFamily: "Helvetica", fontSize: 16 }, { children: getTimeAgo(createdAt) }), void 0)] }), void 0),
                _jsx(Box, __assign({ pt: 16 }, { children: _jsx(PostText, { text: text }, void 0) }), void 0)] }), void 0) }), void 0));
};
export default ZecPostFeedItem;
