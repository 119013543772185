var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @ts-ignore
import { Svg, Path } from '@react-platform/svg';
export var RepostIcon = function (_a) {
    var _b = _a.fill, fill = _b === void 0 ? '#000000' : _b;
    return ( // @ts-ignore
    _jsx(Svg, __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", overflow: "visible" }, { children: _jsx(Path, { d: "M5.56098 5L10.122 9.56098H6.63415V16H13.6098L15.7561 18.1463H6.63415C5.44875 18.1463 4.4878 17.1854 4.4878 16V9.56098H1L5.56098 5ZM18.439 18.4146L13.878 13.8537H17.3659V7.41463H10.3902L8.2439 5.26829H17.3659C18.5512 5.26829 19.5122 6.22924 19.5122 7.41463V13.8537H23L18.439 18.4146Z", fill: fill }, void 0) }), void 0));
};
export default RepostIcon;
